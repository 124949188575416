
import { defineComponent, ref } from 'vue'
import ComposeFooter from '@/components/pages/compose/ComposeFooter.vue'
import ComposeScheduleSection from '@/components/pages/compose/ComposeScheduleSection.vue'
import RecipientsEditor from '@/components/shared/compose/RecipientsEditor.vue'
import MessageEditor from '@/components/shared/compose/MessageEditor.vue'
import SenderSettings from '@/components/shared/compose/SenderSettings.vue'
import ComposeFormLine from '@/components/shared/compose/ComposeFormLine.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ComposeMessage',
  components: {
    ComposeFormLine,
    RecipientsEditor,
    SenderSettings,
    MessageEditor,
    ComposeScheduleSection,
    ComposeFooter,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const scheduleOpen = ref(false)

    return {
      scheduleOpen,
      isSmMax,
    }
  },
})
